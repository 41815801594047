// ------- NEW COLORS --------

$grey01: hsl(225, 21%, 7%);
$grey02: hsla(222, 19%, 10%, 0.24);
$grey03: hsl(231, 19%, 12%);
$grey04: hsl(226, 16%, 13%);
$grey05: hsl(228, 16%, 18%);
$grey06: hsl(220, 14%, 20%);
$grey07: hsl(230, 22%, 30%);
$grey08: hsl(230, 20%, 35%);
$grey09: hsl(224, 13%, 57%);
$grey10: hsl(0, 0%, 75%);
$grey11: hsl(217, 19%, 79%);
$grey12: hsl(0, 0%, 91%);
$grey13: hsla(0, 0%, 0%, 0.2);
$grey14: hsla(0, 0%, 0%, 0.24);
$grey_07: rgba(22, 24, 32, 0.7);

$border: rgba(61, 67, 97, 0.5);
$customizerBg: $grey14;

// ------------------------------

// ------------ HOME COLORS -------
$neutral900: #0e1015;
$neutral850: hsl(225, 19%, 9%);
$neutral800: hsl(228, 19%, 11%);
$neutral750: #1c1f2a;
$neutral700: #212433;
$neutral650: #2c2e3e;
$neutral600: #333545;
$neutral500: #424559;
$neutral400: #6a6c7b;
$neutral300: #888b9c;
$neutral200: #b1b5c9;
$neutral100: #fafbff;
$neutral50: #ececef;

$primary950: #190610;
$primary900: #320b1f;
$primary800: #64163e;
$primary700: #96215e;
$primary600: #c82c7d;
$primary500: #da589b;
$primary400: #e179af;
$primary300: #e99bc3;
$primary200: #f0bcd7;
$primary100: #f8deeb;
$primary50: #fbeef5;
$primaryBase: $primary500;
$primaryHover: #3720334d;

$secondary500: #4f46e5;
$secondary400: #736bea;
$secondaryBase: $secondary500;

$positive950: #031109;
$positive900: #082615;
$positive800: #104c2a;
$positive700: #17723f;
$positive600: #1f9854;
$positive500: #27be69;
$positive400: #45d985;
$positive300: #73e2a4;
$positive200: #a2ecc2;
$positive100: #d0f5e1;
$positive50: #e6faee;
$positiveBase: $positive500;

$negative950: #1d0206;
$negative900: #39040b;
$negative800: #730817;
$negative700: #ac0c22;
$negative600: #e5102e;
$negative500: #f2415a;
$negative400: #f5667a;
$negative300: #f78c9c;
$negative200: #fab3bd;
$negative100: #fcd9de;
$negative50: #feecee;
$negativeBase: $negative500;

$warning950: #1a1300;
$warning900: #382900;
$warning800: #6b4f00;
$warning700: #a37800;
$warning600: #d69d00;
$warning500: #ffbf0f;
$warning400: #ffcb3d;
$warning300: #ffd970;
$warning200: #ffe59e;
$warning100: #fff3d1;
$warning50: #fff8e5;
$warningBase: $warning500;

$grad950: #13161e;
$grad900: #1b1e32;
$grad800: #20203e;
$grad700: #262654;
$grad600: #272949;
$grad500: #32345d;
$grad400: #3e4879;
$grad300: #817fa5;
$grad200: #c3bdf4;
$grad100: #e9e7fb;
$tertiary900: #00213d;
$tertiary200: #b3dcff;

// ------------------------------

$darkerPink: rgba(176, 42, 111, 0.867);
$pink: #d43f8c;
$darkModePink: #fe78bd;
$darkerPink: #972c63;
$lightPink: #f2c5dd;
$tabPink: #d43f8c10;
$lightestPink: #fbecf3;
$lightestestPink: hsl(330, 100%, 99%);
$semiDarkPink: rgba(176, 42, 111, 0.445);
$salmonPink: #f1acd0;

$darkBackground: rgb(13, 17, 23);

$darkModeBg: rgb(15, 17, 23);
$darkModeTextColor: #d6dce6;
$translucentBlack: rgba(0, 0, 0, 0.24);
$darkBlackGrey: rgb(23, 23, 23);
$translucentBlack2: rgba(0, 0, 0, 0.2);
$darkerTranslucentBlack: rgba(0, 0, 0, 0.32);
$translucentWhite: rgba(255, 255, 255, 0.08);
$translucentGray: rgba(255, 255, 255, 0.04);
$translucentGray2: rgba(255, 255, 255, 0.05);
$lightNavyBlue: rgba(33, 36, 51, 0.8);
$translucentLightGrey: rgba(28, 28, 28, 0.6);
$almostOpaqueWhite: rgba(255, 255, 255, 0.8);
$timelineDark: #111112;
$white_40: rgba(255, 255, 255, 0.4);
$black_12: rgba(0, 0, 0, 0.12);
$black_90: rgba(0, 0, 0, 0.9);
$black_20: rgba(0, 0, 0, 0.2);
$black_15: rgba(0, 0, 0, 0.15);
$black_25: rgba(0, 0, 0, 0.25);
$white_02: rgba(255, 255, 255, 0.02);
$white_04: rgba(255, 255, 255, 0.04);
$white_10: rgba(255, 255, 255, 0.1);

$lightenValue: 0.15;

$brandColor: #307d53;
$brandColor: $pink;
$lightBrandColor: lighten(
	$color: $brandColor,
	$amount: 5,
);
$lighterBrandColor: lighten(
	$color: $brandColor,
	$amount: 40,
);

//Platform
$darkBackground: rgb(13, 17, 23);
$sectionBackground: #1b1d23;

//Greys
$hoverGrey: rgba(200, 200, 200, 0.05);
$lightHoverGrey: #e2e2e2;
$darkestGrey: #303030;
$darkerGrey: #4b4b4b;
$darkGrey: #727272;
$clipboardGrey: #f2f2f7;
$mediumGrey: #c7c7c7;
$lightGrey: #f0f3ff;
$offWhite: #f5f7fc;
$inputGrey: #d9d9d9;
$inputGrey: #c2c1c1;
$headerGrey: #eef0f2;
$hoverTipGrey: #282828;
$blushGrey: #3d4361;
$mediumBlushGrey: #3d4361;
$darkerBlushGrey: #22273f;
$blackGrey: #1b1b1b;
$black: #000000;

$col: #db546261;

//Reds
$darkModeRed: #ffa69e;
$lightModeRed: #db5461;
$urlRed: rgb(204, 0, 0);

//Greens
$darkModeGreen: #59ffa0;
$darkModeTransGreen: #59ffa137;

$lightModeGreen: #3a9434;
$urlGreen: rgb(0, 155, 0);

//Oranges
$lightModeOrange: #ff965e;
$darkGold: #d89f03;
$lightGold: #fffbec;

$darkModeFillGold: #373429;
$darkModeStrokeGold: #635520;

//Others
$darkPurple: #665aef;
$lightPurple: #e0e7ff;
$indigo200: #c7d2fe;
$indigo700: #4338ca;
$lightIndigo: #b7b3f4;
$blandIndigo: rgba(149, 143, 239, 0.2);
$darkIndigo: rgba(115, 107, 234, 0.4);

//White
$white: #ffffff;

//comments Background
$commentsBG: #adddff;

//Filters
$filter_lightModeRed: invert(42%) sepia(30%) saturate(4883%) hue-rotate(328deg)
	brightness(97%) contrast(76%);
$filter_white: invert(100%) sepia(92%) saturate(27%) hue-rotate(61deg)
	brightness(105%) contrast(100%);
$filter_lightModeGreen: invert(44%) sepia(70%) saturate(481%) hue-rotate(68deg)
	brightness(93%) contrast(85%);

$filter_mediumPurple: invert(81%) sepia(8%) saturate(2398%) hue-rotate(198deg)
	brightness(101%) contrast(98%);
$filter_darkPurple: invert(35%) sepia(77%) saturate(5193%) hue-rotate(236deg)
	brightness(89%) contrast(102%);

$filter_darkGrey: invert(46%) sepia(1%) saturate(0%) hue-rotate(208deg)
	brightness(96%) contrast(94%);
