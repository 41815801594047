@import "../../../../../styles/colors";
@import "../../../../../styles/shadows";

.ScreenshotEditor {
	width: 67rem;
	color: white;
	border: 1px solid $darkerGrey;
	border-radius: 10px;
	display: flex;
	flex-flow: column;
	align-items: center;

	&.ss-editor-video {
		width: 60rem;
	}

	.ScreenshotEditor-buttons {
		display: flex;
		justify-content: space-between;
		padding: 10px 20px;
		background-color: transparentize($blushGrey, 0.4);
		align-items: center;
		width: 100%;
		// background-color: $sectionBackground;

		.screenshot-section {
			display: flex;
			align-items: center;
			gap: 0.4em;

			&.screenshot-buttons-section {
				gap: 0.8em;
				z-index: 1000;
			}
		}

		.color-editor {
			margin-left: 1.4em;
			transition: 0.2s;
		}

		.screenshot-icon {
			height: 20px;
			width: 20px;
			padding: 6px;
			border-radius: 5px;
			box-sizing: content-box;
			transition: 0.3s;

			&.active-tool {
				background-color: $semiDarkPink;
			}

			&:hover {
				&.inactive-tool {
					background-color: $sectionBackground;
				}
			}
		}

		.save-button {
			font-weight: 500;
			background-color: $pink;
			padding: 0.4em 0.7em;
			transition: 0.3s;
			border-radius: 5px;

			&:hover {
				background-color: $darkerPink;
			}
		}
	}

	.ScreenshotEditor-img-container {
		flex-grow: 1;
		margin: 10px 0px;
		border-radius: 2px;
		// height: calc(100% - 100px);
		// width: auto;
		// width: 100%;
		position: relative;
		-webkit-user-drag: none;
		// max-height: 400px;

		&.ScreenshotEditor-src-img {
			&.ScreenshotEditor-mobile {
				width: 200px;
			}
			&.ScreenshotEditor-tablet {
				width: 600px;
			}
		}

		.ScreenshotEditor-img-container-child {
			-webkit-user-drag: none;
		}

		.ScreenshotEditor-img {
			-webkit-user-drag: none;
			user-select: none;
			width: 70rem;

			&.ScreenshotEditor-Gif {
				width: 60rem;

				&.ScreenshotEditor-Gif-laptop {
					width: 60rem;

					&.s {
						width: 45rem;
					}
				}

				&.ScreenshotEditor-Gif-mobile {
					width: 13rem;
				}

				&.ScreenshotEditor-Gif-tablet {
					width: 50rem;
				}
			}
		}
	}

	.speed-selector {
		display: flex;
		align-items: center;
		user-select: none;

		.speed-selector-options {
			// padding: 4px;
			position: relative;
		}

		.speed-selector-selected {
			user-select: none;
			background-color: $sectionBackground;
			border-radius: 4px;
			font-size: 0.9rem;
			font-weight: 500;
			padding: 0.2rem 0.8rem;
			margin-left: 0.5rem;
			cursor: pointer;
		}

		.speed-selector-dropdownmenu {
			position: absolute;
			background-color: $sectionBackground;
			box-shadow: $boxShadow;
			z-index: 99;
			padding: 0.8rem 0.6rem;
			left: 0;
			margin-top: 0.7rem;
			border-radius: 4px;
			display: flex;
			flex-flow: column;
			gap: 0.3rem;
			opacity: 0.9;
		}

		.speed-option {
			width: 64px;
			transition: 0.3s;
			cursor: pointer;
			padding: 0.2rem 0.4rem;
			border-radius: 5px;
			font-size: 0.9rem;
			font-weight: 500;

			&:hover {
				background-color: $semiDarkPink;
			}
		}
	}
}

.ScreenshotEditor-img-selector {
	position: absolute;
	user-select: none;
	border: 1px solid $semiDarkPink;
	box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.7);
	z-index: 100;
}
