@import "../../../../../styles/colors";

.gigauser-scrubber-slider {
	.gigauser-scrubber-slidertrack {
		border-radius: 0px;
		height: 6px;
	}

	.gigauser-scrubber-sliderthumb {
		visibility: hidden;
		height: 14px;
		width: 14px;

		border: 2px solid $pink;
	}

	.gigauser-scrubber-sliderfilledtrack {
		background-color: $pink;

		&.gigauser-smooth-track {
			//transition: width 0.3s linear;
		}
	}

	&:hover {
		.gigauser-scrubber-sliderthumb {
			visibility: visible;
		}
	}
}
