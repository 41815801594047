@import "../../../styles/colors";

.Cutton {
	&.fullWidth {
		width: 100%;
	}
}

.Home,
.AuthFormLayout {
	.Cutton-Button {
		padding: 12px 24px;
		font-family: "Manrope";
		height: auto;
	}
}

.EditorPlatform {
	.Cutton-Button {
		padding-left: 0.5em;
		padding-right: 0.5em;
	}
}

.Cutton-Button {
	border: 1px solid;
	font-weight: 600;
	transition: 0.3s;
	font-family: "Manrope";
	height: auto;
	max-height: 100%;
	gap: 0.5rem;

	&.Cutton-fullWidth {
		width: 100%;
		padding-top: 0.8rem;
		padding-bottom: 0.8rem;
		font-family: "Manrope";
		height: auto;
		padding: 12px 24px;
	}

	.chakra-button__icon {
		margin-inline-end: 0.4em;
	}

	&.magicShadow {
		&:hover {
			box-shadow:
				0 0 6px rgba(31, 18, 184, 1),
				0 0 12px rgba(157, 3, 83, 1);
		}
	}

	&.primary {
		border-color: $primary500;
		background-color: $primaryBase;

		&.danger {
			border-color: $negative500;
			background-color: $negative400;
		}

		&:hover {
			&.Cutton-notloading {
				background-color: $primary600;
				// background-color: transparentize($semiDarkPink, 0.6);
			}

			&.Cutton-loading {
				background-color: $pink;
			}

			&.danger {
				background-color: $negative500;
			}
		}
	}

	&.quaternary {
		border: none;
		background-color: transparent;

		&.danger {
			color: $negative300;
		}

		&.pink {
			color: $primary500;
		}

		&:hover {
			&.Cutton-notloading {
				background-color: transparent;
				color: $primary300;

				// background-color: transparentize($semiDarkPink, 0.6)
			}

			&.Cutton-loading {
				background-color: $grey04;
			}

			&.danger {
				background-color: transparentize(
					$color: $negative500,
					$amount: 0.9
				);
			}

			&.pink {
				background-color: $primaryHover;
			}
		}
	}

	svg {
		height: 20px;
		width: 20px;
	}

	.Cutton-right-icon-container {
		opacity: 0.8;
		margin: 0 0.1em;
		transition: 0.3s;
	}

	.Cutton-beta-label {
		font-size: 0.8rem;
		line-height: 0.8rem;
		background-color: $semiDarkPink;
		font-weight: bold;
		padding: 0.4rem;
		border-radius: 4px;
		margin-left: 0.7rem;
	}

	&:hover {
		.Cutton-right-icon-container {
			transform: translateY(0.15em);
		}
	}
}

.Cutton {
	&.s {
		svg {
			height: 1rem;
			width: 1rem;
		}

		.Cutton-Button {
			font-size: 0.85rem;
			padding: 0.8rem;

			&.Cutton-fullWidth {
				padding: 1.4rem;
			}
		}
	}

	&.xxl {
		.Cutton-Button {
			font-size: 1.2rem;
			padding: 1rem;

			&.Cutton-fullWidth {
				padding: 1.4rem;
			}
		}
	}

	&.n {
		height: 40px;
	}
}

.gigauser-dark {
	.Cutton-Button {
		&.secondary {
			border-color: transparent;
			background-color: $grey07;

			&:hover {
				&.Cutton-notloading {
					background-color: #2a2f46;
					// background-color: transparentize($semiDarkPink, 0.6);
				}

				&.Cutton-loading {
					background-color: $blushGrey;
				}
			}
		}

		&.tertiary {
			border-color: $neutral700;
			background-color: $neutral800;

			&:hover {
				&.Cutton-notloading {
					background-color: $neutral700;
					// background-color: transparentize($semiDarkPink, 0.6);
				}

				&.Cutton-loading {
					background-color: $grey04;
				}
			}
		}
	}
}

.gigauser-light {
	.Cutton-Button {
		font-family: "Manrope";

		&.secondary {
			border-color: $pink;
			background-color: $lightestestPink;
			color: $pink;

			&:hover {
				&.Cutton-notloading {
					background-color: hsl(330, 100%, 96%);
					// background-color: transparentize($semiDarkPink, 0.6);
				}

				&.Cutton-loading {
					background-color: hsl(330, 100%, 96%);
					// background-color: $blushGrey
				}
			}
		}

		&.tertiary {
			border-color: $grey08;
			background-color: white;
			color: $grey06;

			&:hover {
				&.Cutton-notloading {
					background-color: #f2f2f7;
					// background-color: transparentize($semiDarkPink, 0.6);
				}

				&.Cutton-loading {
					background-color: $grey04;
				}
			}
		}
	}
}
