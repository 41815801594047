.Cutton.fullWidth {
	width: 100%;
}

.Home .Cutton-Button,
.AuthFormLayout .Cutton-Button {
	padding: 12px 24px;
	font-family: "Manrope";
	height: auto;
}

.EditorPlatform .Cutton-Button {
	padding-left: 0.5em;
	padding-right: 0.5em;
}

.Cutton-Button {
	border: 1px solid;
	font-weight: 600;
	transition: 0.3s;
	font-family: "Manrope";
	height: auto;
	max-height: 100%;
	gap: 0.5rem;
}
.Cutton-Button.Cutton-fullWidth {
	width: 100%;
	padding-top: 0.8rem;
	padding-bottom: 0.8rem;
	font-family: "Manrope";
	height: auto;
	padding: 12px 24px;
}
.Cutton-Button .chakra-button__icon {
	margin-inline-end: 0.4em;
}
.Cutton-Button.magicShadow:hover {
	box-shadow:
		0 0 6px rgb(31, 18, 184),
		0 0 12px rgb(157, 3, 83);
}
.Cutton-Button.primary {
	border-color: #da589b;
	background-color: #da589b;
}
.Cutton-Button.primary.danger {
	border-color: #f2415a;
	background-color: #f5667a;
}
.Cutton-Button.primary:hover.Cutton-notloading {
	background-color: #c82c7d;
}
.Cutton-Button.primary:hover.Cutton-loading {
	background-color: #d43f8c;
}
.Cutton-Button.primary:hover.danger {
	background-color: #f2415a;
}
.Cutton-Button.quaternary {
	border: none;
	background-color: transparent;
}
.Cutton-Button.quaternary.danger {
	color: #f78c9c;
}
.Cutton-Button.quaternary.pink {
	color: #da589b;
}
.Cutton-Button.quaternary:hover.Cutton-notloading {
	background-color: transparent;
	color: #e99bc3;
}
.Cutton-Button.quaternary:hover.Cutton-loading {
	background-color: hsl(226, 16%, 13%);
}
.Cutton-Button.quaternary:hover.danger {
	background-color: rgba(242, 65, 90, 0.1);
}
.Cutton-Button.quaternary:hover.pink {
	background-color: rgba(55, 32, 51, 0.3019607843);
}
.Cutton-Button svg {
	height: 20px;
	width: 20px;
}
.Cutton-Button .Cutton-right-icon-container {
	opacity: 0.8;
	margin: 0 0.1em;
	transition: 0.3s;
}
.Cutton-Button .Cutton-beta-label {
	font-size: 0.8rem;
	line-height: 0.8rem;
	background-color: rgba(176, 42, 111, 0.445);
	font-weight: bold;
	padding: 0.4rem;
	border-radius: 4px;
	margin-left: 0.7rem;
}
.Cutton-Button:hover .Cutton-right-icon-container {
	transform: translateY(0.15em);
}

.Cutton.s svg {
	height: 1rem;
	width: 1rem;
}
.Cutton.s .Cutton-Button {
	font-size: 0.85rem;
	padding: 0.8rem;
}
.Cutton.s .Cutton-Button.Cutton-fullWidth {
	padding: 1.4rem;
}
.Cutton.xxl .Cutton-Button {
	font-size: 1.2rem;
	padding: 1rem;
}
.Cutton.xxl .Cutton-Button.Cutton-fullWidth {
	padding: 1.4rem;
}
.Cutton.n {
	height: 40px;
}

.gigauser-dark .Cutton-Button.secondary {
	border-color: transparent;
	background-color: hsl(230, 22%, 30%);
}
.gigauser-dark .Cutton-Button.secondary:hover.Cutton-notloading {
	background-color: #2a2f46;
}
.gigauser-dark .Cutton-Button.secondary:hover.Cutton-loading {
	background-color: #3d4361;
}
.gigauser-dark .Cutton-Button.tertiary {
	border-color: #212433;
	background-color: hsl(228, 19%, 11%);
}
.gigauser-dark .Cutton-Button.tertiary:hover.Cutton-notloading {
	background-color: #212433;
}
.gigauser-dark .Cutton-Button.tertiary:hover.Cutton-loading {
	background-color: hsl(226, 16%, 13%);
}

.gigauser-light .Cutton-Button {
	font-family: "Manrope";
}
.gigauser-light .Cutton-Button.secondary {
	border-color: #d43f8c;
	background-color: hsl(330, 100%, 99%);
	color: #d43f8c;
}
.gigauser-light .Cutton-Button.secondary:hover.Cutton-notloading {
	background-color: hsl(330, 100%, 96%);
}
.gigauser-light .Cutton-Button.secondary:hover.Cutton-loading {
	background-color: hsl(330, 100%, 96%);
}
.gigauser-light .Cutton-Button.tertiary {
	border-color: hsl(230, 20%, 35%);
	background-color: white;
	color: hsl(220, 14%, 20%);
}
.gigauser-light .Cutton-Button.tertiary:hover.Cutton-notloading {
	background-color: #f2f2f7;
}
.gigauser-light .Cutton-Button.tertiary:hover.Cutton-loading {
	background-color: hsl(226, 16%, 13%);
} /*# sourceMappingURL=Cutton.css.map */
