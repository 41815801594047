.gigauser-canvas-blackscreen {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.558);
	height: 100vh;
	width: 100vw;
	-webkit-backdrop-filter: blur(1rem);
	backdrop-filter: blur(1rem);
	top: 0;
	left: 0;
	z-index: 99998;
}

.gigauser-CanvasPlayerController {
	max-width: 99%;
	max-height: 99%;
	position: relative;
	width: 80vw;
}
.gigauser-CanvasPlayerController.gigauser-CanvasPlayerMobile {
	width: 21vw;
}
.gigauser-CanvasPlayerController.gigauser-fullscreen-canvas {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 99999;
	width: 90vw;
	height: 100%;
	transform: translate(-50%, -50%);
}
.gigauser-CanvasPlayerController .thumbnail-play {
	width: -moz-fit-content;
	width: fit-content;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	text-align: center;
	transition: 0.3s;
	opacity: 1;
	transition: opacity 0.3s;
	z-index: 11;
}
.gigauser-CanvasPlayerController .thumbnail-play.gigauser-hide-thumbnail-play {
	opacity: 0;
}
.gigauser-CanvasPlayerController
	.thumbnail-play
	.thumbnail-play-icon-container {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.gigauser-CanvasPlayerController .thumbnail-play .thumbnail-play-icon {
	position: absolute;
	z-index: 3;
	height: 48px;
	width: 48px;
	cursor: pointer;
	opacity: 0.8;
	transition: 0.3s;
	transform: translateX(14%);
}
.gigauser-CanvasPlayerController .thumbnail-play .thumbnail-play-bg {
	background-color: var(--gigauser-brand-color);
	padding: 64px;
	border-radius: 99px;
	border-color: rgba(255, 255, 255, 0.279);
	border: 12px solid rgba(255, 255, 255, 0.279);
	height: 64px;
	width: 64px;
	opacity: 0.8;
	transition: 0.3s;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
}
.gigauser-CanvasPlayerController .thumbnail-clueso-preview {
	background-color: rgba(0, 0, 0, 0.662745098);
	color: white;
	font-weight: 600;
	display: flex;
	align-items: center;
	gap: 0.8em;
	padding: 0.7em;
	border-radius: 10px;
	font-size: 0.8em;
	justify-content: center;
	margin-top: 1em;
	text-decoration: none;
}
.gigauser-CanvasPlayerController
	.thumbnail-clueso-preview
	.thumbnail-clueso-logo {
	height: 1.6em;
}
.gigauser-CanvasPlayerController:hover .thumbnail-play-bg {
	opacity: 0.9;
	height: 48px;
	width: 48px;
	padding: 54px;
	border: 10px solid rgba(255, 255, 255, 0.279);
}
.gigauser-CanvasPlayerController:hover .thumbnail-play-icon {
	opacity: 0.9;
	height: 40px;
	width: 40px;
	cursor: pointer;
}
.gigauser-CanvasPlayerController .flash-play {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	height: 4.5em;
	width: 4.5em;
	background-color: var(--gigauser-brand-color);
	justify-content: center;
	align-items: center;
	border-radius: 999px;
	opacity: 0;
	transition: 0.2s;
}
.gigauser-CanvasPlayerController .flash-play .flash-icon-container {
	height: 2em;
	width: 2em;
	display: flex;
	align-items: center;
}
.gigauser-CanvasPlayerController .flash-play.flash-show {
	opacity: 0.6;
	scale: 1;
	display: flex;
}
.gigauser-CanvasPlayerController .gigauser-canvasplayer-controls {
	opacity: 0;
	transition: opacity 0.3s;
}
.gigauser-CanvasPlayerController
	.gigauser-canvasplayer-controls.gigauser-show-controls {
	opacity: 1;
}
.gigauser-CanvasPlayerController .gigauser-canvasplayer-controls-fullscreen {
	opacity: 1;
	transition: opacity 0.3s;
}
.gigauser-CanvasPlayerController
	.gigauser-canvasplayer-controls-fullscreen
	.gigauser-show-controls {
	opacity: 1;
}
.gigauser-CanvasPlayerController
	.gigauser-canvasplayer-controls-fullscreen
	.gigauser-hide-controls {
	opacity: 0;
}
.gigauser-CanvasPlayerController
	.gigauser-canvasplayer-controls-fullscreen
	.gigauser-clueso-scrim {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	border-radius: 10px;
	z-index: 2;
	opacity: 1;
}
.gigauser-CanvasPlayerController
	.gigauser-canvasplayer-controls-fullscreen
	.gigauser-fullscreen-clueso-branding {
	position: absolute;
	left: 1em;
	top: 0px;
	z-index: 3;
	opacity: 1;
}
.gigauser-CanvasPlayerController
	.gigauser-canvasplayer-controls-fullscreen
	.gigauser-controller-footer {
	position: absolute;
	bottom: 0px;
	width: 100%;
	z-index: 3;
	opacity: 1;
}

.gigauser-mobile .gigauser-CanvasPlayerController {
	width: 100vw;
}
.gigauser-mobile .gigauser-CanvasPlayerController .thumbnail-play-icon {
	cursor: pointer;
	height: 24px;
	width: 24px;
}
.gigauser-mobile .gigauser-CanvasPlayerController .thumbnail-play-bg {
	height: 36px;
	width: 36px;
	padding: 36px;
	box-sizing: border-box;
	border: 6px solid rgba(255, 255, 255, 0.279);
}
.gigauser-mobile .gigauser-CanvasPlayerController .thumbnail-clueso-preview {
	font-size: 0.6em;
}
.gigauser-mobile .gigauser-CanvasPlayerController .flash-play {
	height: 2em;
	width: 2em;
}
.gigauser-mobile
	.gigauser-CanvasPlayerController
	.flash-play
	.flash-icon-container {
	height: 1em;
} /*# sourceMappingURL=CanvasPlayerController.css.map */
