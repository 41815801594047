.ScreenshotEditor {
	width: 67rem;
	color: white;
	border: 1px solid #4b4b4b;
	border-radius: 10px;
	display: flex;
	flex-flow: column;
	align-items: center;
}
.ScreenshotEditor.ss-editor-video {
	width: 60rem;
}
.ScreenshotEditor .ScreenshotEditor-buttons {
	display: flex;
	justify-content: space-between;
	padding: 10px 20px;
	background-color: rgba(61, 67, 97, 0.6);
	align-items: center;
	width: 100%;
}
.ScreenshotEditor .ScreenshotEditor-buttons .screenshot-section {
	display: flex;
	align-items: center;
	gap: 0.4em;
}
.ScreenshotEditor
	.ScreenshotEditor-buttons
	.screenshot-section.screenshot-buttons-section {
	gap: 0.8em;
	z-index: 1000;
}
.ScreenshotEditor .ScreenshotEditor-buttons .color-editor {
	margin-left: 1.4em;
	transition: 0.2s;
}
.ScreenshotEditor .ScreenshotEditor-buttons .screenshot-icon {
	height: 20px;
	width: 20px;
	padding: 6px;
	border-radius: 5px;
	box-sizing: content-box;
	transition: 0.3s;
}
.ScreenshotEditor .ScreenshotEditor-buttons .screenshot-icon.active-tool {
	background-color: rgba(176, 42, 111, 0.445);
}
.ScreenshotEditor
	.ScreenshotEditor-buttons
	.screenshot-icon:hover.inactive-tool {
	background-color: #1b1d23;
}
.ScreenshotEditor .ScreenshotEditor-buttons .save-button {
	font-weight: 500;
	background-color: #d43f8c;
	padding: 0.4em 0.7em;
	transition: 0.3s;
	border-radius: 5px;
}
.ScreenshotEditor .ScreenshotEditor-buttons .save-button:hover {
	background-color: #972c63;
}
.ScreenshotEditor .ScreenshotEditor-img-container {
	flex-grow: 1;
	margin: 10px 0px;
	border-radius: 2px;
	position: relative;
	-webkit-user-drag: none;
}
.ScreenshotEditor
	.ScreenshotEditor-img-container.ScreenshotEditor-src-img.ScreenshotEditor-mobile {
	width: 200px;
}
.ScreenshotEditor
	.ScreenshotEditor-img-container.ScreenshotEditor-src-img.ScreenshotEditor-tablet {
	width: 600px;
}
.ScreenshotEditor
	.ScreenshotEditor-img-container
	.ScreenshotEditor-img-container-child {
	-webkit-user-drag: none;
}
.ScreenshotEditor .ScreenshotEditor-img-container .ScreenshotEditor-img {
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	width: 70rem;
}
.ScreenshotEditor
	.ScreenshotEditor-img-container
	.ScreenshotEditor-img.ScreenshotEditor-Gif {
	width: 60rem;
}
.ScreenshotEditor
	.ScreenshotEditor-img-container
	.ScreenshotEditor-img.ScreenshotEditor-Gif.ScreenshotEditor-Gif-laptop {
	width: 60rem;
}
.ScreenshotEditor
	.ScreenshotEditor-img-container
	.ScreenshotEditor-img.ScreenshotEditor-Gif.ScreenshotEditor-Gif-laptop.s {
	width: 45rem;
}
.ScreenshotEditor
	.ScreenshotEditor-img-container
	.ScreenshotEditor-img.ScreenshotEditor-Gif.ScreenshotEditor-Gif-mobile {
	width: 13rem;
}
.ScreenshotEditor
	.ScreenshotEditor-img-container
	.ScreenshotEditor-img.ScreenshotEditor-Gif.ScreenshotEditor-Gif-tablet {
	width: 50rem;
}
.ScreenshotEditor .speed-selector {
	display: flex;
	align-items: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.ScreenshotEditor .speed-selector .speed-selector-options {
	position: relative;
}
.ScreenshotEditor .speed-selector .speed-selector-selected {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	background-color: #1b1d23;
	border-radius: 4px;
	font-size: 0.9rem;
	font-weight: 500;
	padding: 0.2rem 0.8rem;
	margin-left: 0.5rem;
	cursor: pointer;
}
.ScreenshotEditor .speed-selector .speed-selector-dropdownmenu {
	position: absolute;
	background-color: #1b1d23;
	box-shadow:
		0 4px 6px 0 rgba(0, 0, 0, 0.2),
		0 3px 10px 0 rgba(0, 0, 0, 0.195);
	z-index: 99;
	padding: 0.8rem 0.6rem;
	left: 0;
	margin-top: 0.7rem;
	border-radius: 4px;
	display: flex;
	flex-flow: column;
	gap: 0.3rem;
	opacity: 0.9;
}
.ScreenshotEditor .speed-selector .speed-option {
	width: 64px;
	transition: 0.3s;
	cursor: pointer;
	padding: 0.2rem 0.4rem;
	border-radius: 5px;
	font-size: 0.9rem;
	font-weight: 500;
}
.ScreenshotEditor .speed-selector .speed-option:hover {
	background-color: rgba(176, 42, 111, 0.445);
}

.ScreenshotEditor-img-selector {
	position: absolute;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	border: 1px solid rgba(176, 42, 111, 0.445);
	box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.7);
	z-index: 100;
} /*# sourceMappingURL=ScreenshotEditor.css.map */
