.gigauser-scrubber-slider .gigauser-scrubber-slidertrack {
	border-radius: 0px;
	height: 6px;
}
.gigauser-scrubber-slider .gigauser-scrubber-sliderthumb {
	visibility: hidden;
	height: 14px;
	width: 14px;
	border: 2px solid #d43f8c;
}
.gigauser-scrubber-slider .gigauser-scrubber-sliderfilledtrack {
	background-color: #d43f8c;
}
.gigauser-scrubber-slider:hover .gigauser-scrubber-sliderthumb {
	visibility: visible;
} /*# sourceMappingURL=CanvasScrubber.css.map */
