@import "../../../styles/colors";

.gigauser-canvasplayer {
	display: flex;
	// width: 100%;
	height: 100%;
	// width: auto;
	align-items: center;
	justify-content: center;
	position: relative;
	box-shadow: 0 0rem 1.2rem #da589b1d;
	overflow: hidden;

	&::before {
		content: "Click play to preview your video"; /* New line represented by \n */
		white-space: pre; /* Preserves whitespace and new lines */
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%); /* Center the text block */
		font-size: 16px;
		font-style: italic; /* Make text italic */
		color: #d6dce6;
		text-align: center; /* Align text lines to the center */
		z-index: 1; /* Ensure it's in the background */
	}

	.gigauser-video-canvas {
		// border: 1px solid $mediumGrey;
		z-index: 2;
		border-radius: 4px;
		width: 100%; /* Maximum width is the parent's width */
		height: 100%; /* Maximum height is the parent's height */
		object-fit: contain; /* Ensures aspect ratio is maintained */
		// background: url("https://clueso-public-assets.s3.ap-south-1.amazonaws.com/9ca802c0-d09f-45ba-82fb-cd3095c9d754/video_assets/Bookend.png");
	}
	.gigauser-video-canvas-hovered {
		border: 2px solid $pink;
	}

	.horizontal-line {
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		height: 0;
		border-top: 1px dashed $pink;
		z-index: 1000;
	}

	.vertical-line {
		position: absolute;
		top: 0;
		left: 50%;
		width: 0;
		height: 100%;
		border-left: 1px dashed $pink;
		z-index: 1000;
	}
}

.gigauser-canvasplayer-message {
	color: $darkModeTextColor;
	// position: absolute;
	text-align: center;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-style: italic;
}

.canvas-hoverElementDiv {
	position: absolute;
	user-select: none;
	border: 2px solid $pink;
	background-color: transparent;
	color: transparent;
	z-index: 99;

	&.hide-border {
		border: none;
	}
}
.gigauser-canvasplayer-spinner {
	height: 60vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
