.dark-app-texteditor-toolbar-dropdown {
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 0.25rem;
	padding: 0.5rem;
	margin: 0.125rem;
	cursor: pointer;
}
.dark-app-texteditor-toolbar-dropdown:hover {
	background-color: rgba(255, 255, 255, 0.08);
	box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.2);
}
.dark-app-texteditor-toolbar-dropdown
	.dark-app-texteditor-toolbar-dropdown-content {
	display: none;
	position: absolute;
	background-color: #1b1d23; /* Dark background color */
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
	z-index: 1000;
	width: -moz-fit-content;
	width: fit-content;
	background-color: #13161e;
	border-radius: 0.3rem;
	padding: 0.2rem;
}
.dark-app-texteditor-toolbar-dropdown
	.dark-app-texteditor-toolbar-dropdown-content
	.dark-app-texteditor-toolbar-dropdown-content-item {
	cursor: pointer;
	text-wrap: nowrap;
	color: #ffffff;
	font-family: "Manrope";
	font-weight: 500;
	border-radius: 0.3rem;
	margin: 0.4rem;
	padding: 0.3rem;
}
.dark-app-texteditor-toolbar-dropdown
	.dark-app-texteditor-toolbar-dropdown-content
	.dark-app-texteditor-toolbar-dropdown-content-item:hover {
	background: linear-gradient(180deg, #b7b3f4 0%, #f1acd0 100%),
		linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	background-blend-mode: overlay;
}
.dark-app-texteditor-toolbar-dropdown:hover
	.dark-app-texteditor-toolbar-dropdown-content {
	display: block;
} /*# sourceMappingURL=ScreenshotDropdown.css.map */
