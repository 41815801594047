.HelpCenterIndex-Container {
	height: 100%;
	display: flex;
	flex-flow: column;
	position: fixed;
	border-right: 1px solid rgb(var(--gigauser-brand-color-rgb), 0.05);
}

.HelpCenterIndex {
	box-sizing: border-box;
	flex-grow: 1;
	width: 20em;
	overflow-y: auto;
	padding: 0em 1.2em;
	padding-top: 2em;
	padding-bottom: 2em;
	text-align: left;
	padding-top: 6em;
	display: flex;
	flex-flow: column;
}
.HelpCenterIndex .mainFolderAccordion {
	margin-bottom: 2em;
	width: 100%;
}
.HelpCenterIndex.hide {
	display: none;
}

.powered-by-Clueso-container {
	width: 100%;
	font-size: 0.8em;
	box-sizing: border-box;
	padding: 1.5em;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	cursor: pointer;
}
.powered-by-Clueso-container .powered-by-Clueso {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.8em;
	text-decoration: none;
}
.powered-by-Clueso-container .helpcenter-index-footer-logo {
	height: 1.5em;
	opacity: 0.6;
	transition: 0.3s;
	cursor: pointer;
}
.powered-by-Clueso-container:hover .helpcenter-index-footer-logo {
	opacity: 1;
}

.gigauser-light .powered-by-Clueso {
	color: #4b4b4b;
}

.gigauser-dark .HelpCenterIndex {
	border-right: 1px solid rgb(var(--gigauser-darkMode-brand-color-rgb), 0.1);
}
.gigauser-dark .powered-by-Clueso {
	color: #c7c7c7;
} /*# sourceMappingURL=HelpCenterIndex.css.map */
