.gigauser-canvas-controls-buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1em 1.2em;
	padding-top: 0.4em;
}
.gigauser-canvas-controls-buttons .gigauser-canvas-control-play {
	height: 20px;
	width: 20px;
}
.gigauser-canvas-controls-buttons .gigauser-canvas-control-button {
	height: 24px;
	width: 24px;
}
.gigauser-canvas-controls-buttons .gigauser-canvas-controls-left {
	display: flex;
	align-items: center;
	gap: 10px;
}
.gigauser-canvas-controls-buttons
	.gigauser-canvas-controls-left
	.gigauser-canvas-volume-slider {
	width: 0px;
	overflow: hidden;
	box-sizing: border-box;
	transition: 0.3s;
	padding: 4px 0px;
	display: flex;
	flex-flow: column;
	justify-content: center;
}
.gigauser-canvas-controls-buttons
	.gigauser-canvas-controls-left
	.gigauser-canvas-volume-container {
	display: flex;
	align-items: center;
}
.gigauser-canvas-controls-buttons
	.gigauser-canvas-controls-left
	.gigauser-canvas-volume-container:hover
	.gigauser-canvas-volume-slider {
	width: 100px;
	padding: 4px 12px;
}
.gigauser-canvas-controls-buttons
	.gigauser-canvas-controls-left
	.gigauser-volume-slidertrack {
	height: 8px;
}
.gigauser-canvas-controls-buttons .gigauser-canvas-controls-right {
	display: flex;
	align-items: center;
	gap: 12px;
}
.gigauser-canvas-controls-buttons .gigauser-canvas-time-display {
	color: white;
	font-weight: 400;
	font-size: 1em;
}
.gigauser-canvas-controls-buttons
	.gigauser-canvas-time-display
	.gigauser-canvas-time-item {
	width: 0.6em;
	display: inline-flex;
	text-align: center;
	justify-content: center;
}
.gigauser-canvas-controls-buttons
	.gigauser-canvas-time-display
	.gigauser-canvas-time-slash {
	margin: 0px 0.3em;
} /*# sourceMappingURL=CanvasControls.css.map */
