.gigauser-canvasplayer-controls {
	.gigauser-clueso-scrim {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		border-radius: 10px;
		background-color: rgba(0, 0, 0, 0.3);
		z-index: 2;
	}

	.gigauser-clueso-branding {
		position: absolute;
		left: 1em;
		top: 0px;
		z-index: 3;
	}

	.gigauser-controller-footer {
		position: absolute;
		bottom: 0px;
		width: 100%;
		z-index: 3;
	}
}
