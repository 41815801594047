.gigauser-canvasplayer {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	position: relative;
	box-shadow: 0 0rem 1.2rem rgba(218, 88, 155, 0.1137254902);
	overflow: hidden;
}
.gigauser-canvasplayer::before {
	content: "Click play to preview your video"; /* New line represented by \n */
	white-space: pre; /* Preserves whitespace and new lines */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); /* Center the text block */
	font-size: 16px;
	font-style: italic; /* Make text italic */
	color: #d6dce6;
	text-align: center; /* Align text lines to the center */
	z-index: 1; /* Ensure it's in the background */
}
.gigauser-canvasplayer .gigauser-video-canvas {
	z-index: 2;
	border-radius: 4px;
	width: 100%; /* Maximum width is the parent's width */
	height: 100%; /* Maximum height is the parent's height */
	-o-object-fit: contain;
	object-fit: contain; /* Ensures aspect ratio is maintained */
}
.gigauser-canvasplayer .gigauser-video-canvas-hovered {
	border: 2px solid #d43f8c;
}
.gigauser-canvasplayer .horizontal-line {
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	height: 0;
	border-top: 1px dashed #d43f8c;
	z-index: 1000;
}
.gigauser-canvasplayer .vertical-line {
	position: absolute;
	top: 0;
	left: 50%;
	width: 0;
	height: 100%;
	border-left: 1px dashed #d43f8c;
	z-index: 1000;
}

.gigauser-canvasplayer-message {
	color: #d6dce6;
	text-align: center;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-style: italic;
}

.canvas-hoverElementDiv {
	position: absolute;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	border: 2px solid #d43f8c;
	background-color: transparent;
	color: transparent;
	z-index: 99;
}
.canvas-hoverElementDiv.hide-border {
	border: none;
}

.gigauser-canvasplayer-spinner {
	height: 60vh;
	display: flex;
	align-items: center;
	justify-content: center;
} /*# sourceMappingURL=CanvasPlayer.css.map */
